.mission-overlay {
    color: #fff;
    background: #007bff;
    max-width: 530px;
    float: right;
    margin-top: -140px;
    position: relative;
    padding: 10px;
}
.announcements {
    color: #fff;
    background: #007bff;
    float: right;
    position: relative;
    padding: 10px;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .mission-overlay {
        color: #fff;
        background: none;
        float: none;
        margin-top:0;
        position: relative;
        padding: 10px;
        max-width:none;
    }

    .announcements {
        color: #fff;
        background: none;
        float: right;
        position: relative;
        padding: 10px;
        width: 100%;
        font-weight: 500;
        font-size: 24px;
    }
}